let lastScrollPosition = 1;

function handleScrollEvent() {
  if( window.scrollY > 0 ) {
    lastScrollPosition = window.scrollY;
  }
}

function throttle(func, limit) {
  let inThrottle;
  return function(...args) {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
}

function handleFullScreenChange() {
  if (!document.fullscreenElement) {
    window.scrollTo({
      top: lastScrollPosition,
      behavior: 'instant'
    });
  }
}

window.addEventListener('scroll', throttle(handleScrollEvent, 100));

document.querySelectorAll('iframe.sw-video__container__iframe').forEach(video => {
  video.addEventListener('fullscreenchange', handleFullScreenChange);
});

